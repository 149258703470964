.site-navbar {
    background-color: #FFF;
}
.navbar-inverse .navbar-toolbar>li>a {
    color: #76828e !important;
}
.navbar-inverse .hamburger .hamburger-bar, .navbar-inverse .hamburger:after, .navbar-inverse .hamburger:before{
	background: #76828e;
}
.navbar-search-overlap .form-control{
    background: #FFF !important;
}
.site-menubar{
	background: #12131a;
}
.site-menubar-footer>a{
    background: #090a0e;
}
.site-menubar-header {
    height: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.site-menubar-header .cover {
    height: 100%;
}
.site-menubar-header .avatar {
    float: left;
    margin-right: 20px;
}
.site-menubar-header .site-menubar-email {
    margin-bottom: 0;
    font-size: 12px;
    color: rgba(255,255,255,.6);
}
.site-menubar-header .site-menubar-user {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 14px;
    color: #fff;
}
.site-menu-item a{
	color: #FFFFFF;
}
.site-menu-item a.active{
	background: #f28e20 !important;
}
.site-menu-item:hover > a{
	background: #f28e20 !important;
	color: #FFFFFF !important;
}
.site-menubar-unfold .site-menu>.site-menu-item>a {
    padding: 0 15px;
}
.input-search-dark .form-control{
	background: #FFFFFF;
}
.page-header-actions {
    width: 330px;
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    background: #FFF;
}
.site-menubar-fold .site-navbar .navbar-container {
    margin-left: 50px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    color: #fff;
    background: #f28e20;
    border-color: #f28e20;
}
.pagination li>a, .pagination li>span, .pagination>li:first-child>a, .pagination>li:first-child>span {
    margin: 0 2px;
    border: 1px solid #888;
    border-radius: 4px;
}
.btn-floating{
	background: #f28e20;
	border-color: #f28e20;
}
.text-truncate {
    font-weight: 500;
}
.page-aside {
    position: relative;
    float: left;
    border-right: none;
    background: #f6f6f6;
    height: 100%;
}

.app-media .page-main {
    background: #f6f6f6;
    margin:0 15px 15px;
}
.page-content-table{
    background: #FFFFFF;
}
.breadcrumb>li+li:before {
    padding: 0 5px;
    color: #3949ab;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    content: "\f105";
}
.page-aside-section:first-child {
    padding-top: 0;
}
.site-menubar-unfold .site-menu-icon {
    margin-right: 0;
}
.page-aside .list-group-item {
    padding: 5px 8px 0;
}
.page-aside .list-group-item .icon {
    color: #f28e20;
    font-size: 30px;
    float: left;
    
}
.page-aside .list-group-item.active>.icon, .page-aside .list-group-item:hover .icon, .page-aside .list-group-item:focus .icon, .page-aside .list-group-item.active:hover{
    color: #FFFFFF !important;
}
.list-group-item{
    background: #f6f6f6;
}
.page-aside .list-group-item {
    line-height: 34px;
    border-radius: 0;
    margin-bottom: 0;
}
.page-aside .list-group-item.active, .page-aside .list-group-item:hover, .page-aside .list-group-item:focus, .page-aside .list-group-item.active:hover{
    background: #f28e20;
    color: #FFFFFF;
}
.page-aside .list-group-item:before {
    font-family: Material-Design-Iconic-Font;
    font-style: normal;
    font-weight: 400;
    content: '\f2fb';
    float: right;
}
.btn-success.focus, .btn-success:focus, .btn-success:hover {
    background-color: #f28e20;
    border-color: #f28e20;
}
.btn-created-folder, .btn-created-folder:hover, .btn-created-folder:focus{
    margin: 0 10px;
    background: #f28e20;
    color: #FFFFFF;
    width: 92%;
}
.list-group h5{
    margin:  0 !important; 
}
.page-aside .list-group {
    margin-bottom: 5px;
}
.folder-info{
    background: #ececec;
    padding: 5px;
    margin-top: 10px;
    margin: 10px;
    border-radius: 5px;
}
.project-info-img img{
    max-width: 100%;
}

body.app-media {
    background: #f3f4f5;
}
.breadcrumb li a{
    color:#0393e6;
}
.nav-tabs-vertical .nav-tabs{
    border-right: none;
    min-width: 250px;
}


.padding-vertical-15 {
    padding-top: 0;
}
.margin-right-25 {
    margin-right: 0px!important;
}
.list-group{
    background: #f6f6f6;
    padding-bottom: 30px;
    margin-bottom: 0;
    padding-top: 10px;
}
.tab-content{
    background: #FFFFFF;
}


.step {
    padding: 20px 20px 2px;
    border-right: 1px solid #ede;
}
.step:last-child{
    border-right: none;
}
.step-number, .step-number-2 {
    color: #FFFFFF;
    background-color: #0195ff;
    top: 0;
}
.step.current .step-number {
    color: #0195ff;
    background-color: #fff;
}
.step-number, .step-number-2 {
    position: relative;
    left: initial;
    width: 40px;
    height: 24px;
    font-size: 18px;
    margin: auto;
    line-height: 24px;
    color: #fff;
    text-align: center;
    background: #0195ff;
    border-radius: 0;
    -webkit-transform: initial;
    -ms-transform: initial;
    -o-transform: initial;
    transform: initial;
}
.step-number-2{
    background: #00bcd4;
}
.step-number~.step-desc, .step-number-2~.step-desc {
    min-height: initial;
    margin-left: inherit;
    text-align: center;
    margin-top: 10px;
}
.steps.row {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: none;
}
.step-title {
    font-size: 16px;
}
.step-number:before, .step-number-2:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 10px solid #0195ff;
}
.step-number-2:before{
    border-bottom: 10px solid #0195ff;
}
.step-number:after, .step-number-2:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #0195ff;
}
.step-number-2:after{
    border-top: 10px solid #0195ff;
}
.current .step-number:before, .step.done .step-number:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 10px solid #FFFFFF;
}
.current .step-number:after, .step.done .step-number:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #FFFFFF;
}
.form-steps .form-group{
    margin-left: 19px;
}
.wizard-pane .form-control{
    border-radius: 0; 
}
.wizard-pane .input-group-btn .btn {
    padding: 6px 40px;
    border-radius: 0;
}
.wizard-pane .input-group-btn .btn-success {
    color: #fff;
    background-color: #CCC;
    border-color: #CCC;
}
.form-steps label{
    font-weight: 500;
}
.list-task ul{
    margin: 0;
    padding-left: 10px;
}
.list-task li{
    list-style: none;
    display: table;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #ececec;
}
.list-task li div{
    display: table-cell;
    vertical-align: middle;
}
.text-task p{
    margin: 0 5px
}
.task-img{
    width: 60px;
}
.wp-img{
    height: 55px;
    width: 55px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0;
}
.projects-avt .avatar{
    width: 50px;
}
.projects-table .avatar img {
    height: 50px;
}
.projects-table>tbody>tr>td, .projects-table>tbody>tr>th, .projects-table>tfoot>tr>td, .projects-table>tfoot>tr>th, .projects-table>thead>tr>td, .projects-table>thead>tr>th{
    vertical-align: middle;
}
.projects-table>tbody>tr>td>p{
    margin:0;
}
.projects-table .avatar i {
    right: 5px;
}

.nav-tabs-vertical .projects-sort{
    padding: 0 10px 20px;
}
.filter-wapp{
    width: 20px;
    height: 20px;
    display: block;
    float: left;
    text-align: center;
    background: #ededed;
    margin-right: 5px;
}
.filter-box .input-group{
    width: 100%;
}
.filter-box .input-group .form-control{
    border-radius: 0;
}
.filter-box .input-group .input-group-addon {
    font-weight: 300;
    background: transparent;
    border-left: none !important;
    border-radius: 0;
}
.filter-box .input-group .form-control:focus{
    border:1px solid #e0e0e0;
}
.filter-box .input-group-icon .form-control{
    border-right: none !important;
}
.step.disabled .step-number {
    background-color: #00bcd4;
}
.app-projects .panel {
    padding: 0;
    box-shadow: none;
    border:none;
}
.app-projects .panel-body {
    padding: 0;
}
.input-wapp{
    float: left;
    width: 45%;
    position: relative;
    margin-left: 15px;
    margin-right: 20px;
    margin-bottom: 10px;
}
.input-wapp input[type=checkbox] {
    display:none;
}

.input-wapp input[type=checkbox] + label
{
   background: #f28e20;
    height: auto;
    width: auto;
    display: inline-block;
    padding: 4px 23px 5px;
    
    margin-bottom: 0;
    color: #FFF;
    min-width: 300px;
    cursor: pointer;
}

.input-wapp input[type=checkbox] + label:after{
    content: "";
    position: absolute;
    top: 4px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top: 11px solid #f28e20;
    border-left: 11px solid #f28e20;
    border-top-left-radius: 4px;
    transform: rotate(-45deg);
    left: -10px;
}

.input-wapp input[type=checkbox]:checked + label
{
    background: #0098f9;
    height: auto;
    width: auto;
    display:inline-block;
    padding: 4px 23px 5px;

}
.input-wapp input[type=checkbox]:checked + label:before{
    content: "\f00c";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color:#FFF;
    margin-left: -23px;
    margin-right: 9px;
}
.input-wapp input[type=checkbox]:checked + label:after{
    content: "";
    position: absolute;
    top: 4px;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-top: 11px solid #0098f9;
    border-left: 11px solid #0098f9;
    border-top-left-radius: 4px;
    transform: rotate(-45deg);
    left: -10px;
}
.select2-container--default .select2-selection {
    border-radius: 0 !important;
}
input.to-labelauty + label {
    float: left;
    margin-right: 10px;
}
.projects-wrap input.to-labelauty + label {
    min-width: auto;
    padding: 10px 25px 10px 10px;
}
.projects-wrap  input.labelauty + label {
    background-color: #f28e20;
    color: #FFFFFF;
}
.projects-wrap  input.labelauty:checked + label {
    background: #0098f9;
}



/*--------------------------------------------------*/

.top-button {
    margin-bottom: 10px;
}
.top-button a {
    font-size: 14px;
    color: #fff;
    background: #ff9933;
    margin-left: 10px;
    border-radius: 8px;
}
.table-cow {
    clear: both;
    position: relative;
    width: 100%;
    display: block;
}
.table-cow:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #d97200;
    top: 53px;
    left: 38px;
    bottom: 0;
    position: absolute;
}
.table-cow b {
    color: #595959;
    font-style: italic;
    font-weight: 400;
    margin-left: 50px;
}
.table-cow p {
    font-size: 16px;
    display: inline-block;
    margin-left: 50px;
    margin-right: 80px;
}
.table-cow p span {
    font-weight: bold;
}
.table-cow p i {
    margin-left: 30px;
    color: #e50000;
    font-weight: bold;
    font-style: normal;
}
.table-cow h6 {
    font-size: 16px;
    color: #f28e20;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 15px;
}
.table-cow h4 {
    font-size: 20px;
    color: #f28e20;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 15px;
}
.table-cow h5 {
    color: #595959;
    font-size: 16px;
    font-weight: bold;
    margin-left: 50px;
}
.table-cow a {
    font-size: 16px;
    text-transform: uppercase;
    background-color: #f28e20;
    border-radius: 8px;
    padding: 5px 19px;
    color: #fff;
    cursor: pointer;
    margin-left: 50px;
}
.table-cow td {
    border-top: 1px solid #f4f4f4;
    display: flex;
    align-items: flex-end;
}
.table-cow .form-group {
    margin-left: 50px;
}
.table-cow .input-wapp input[type=checkbox] + label, .information-workers-content .input-wapp input[type=checkbox] + label  {
    background: #cccccc;
}
.table-cow .input-wapp input[type=checkbox]:checked + label, .information-workers-content .input-wapp input[type=checkbox]:checked + label {
    background: #0098f9;
}
.table-cow .input-wapp input[type=checkbox] + label:after, .information-workers-content .input-wapp input[type=checkbox] + label:after {
    border-top: 11px solid #cccccc;
    border-left: 11px solid #cccccc;
}
.table-cow .input-wapp input[type=checkbox]:checked + label:after, .information-workers-content .input-wapp input[type=checkbox]:checked + label:after {
    border-top: 11px solid #0098f9;
    border-left: 11px solid #0098f9;
}
.top-page-header {
    background: #f28e1f;
    width: 100%;
    padding: 10px 30px;
}
.top-page-header h4, .top-page-header a {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;  
    margin: 0; padding: 0;
}
.cow-modal .modal-header {
    background: #ff9933;
}
.cow-modal .modal-title {
    color: #fff;
}
.cow-modal button.close {
    color: #fff;
    opacity: 1;
}
.modal-send {
    padding-top: 20px;
    padding-bottom: 20px;
    background: rgba(0,0,0,0.1);
}
.modal-send .waves-effect {
    color: #000;
    background: #fff;
    border-color: #ccc;
}   
.modal-send .modal-body {
    padding: 15px;
}
.cow-modal .form-horizontal .form-group {
    margin: 0;
    border-bottom: 1px solid #ccc;
}
.cow-modal .form-horizontal .form-group input {
    border-style: none;
}
.cow-modal .form-horizontal .form-group label {
    color: #000;
}
.cow-modal .form-horizontal .form-group input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #cc9900;
}
.cow-modal .form-horizontal .form-group input::-moz-placeholder { /* Firefox 19+ */
  color: #cc9900;
}
.cow-modal .form-horizontal .form-group input:-ms-input-placeholder { /* IE 10+ */
  color: #cc9900;
}
.cow-modal .form-horizontal .form-group input:-moz-placeholder { /* Firefox 18- */
  color: #cc9900;
}
.cow-modal .select2-primary {
    width: 80%;
    float: right;
}
.cow-modal .select2-container--default .select2-selection {
    border-style: none;
}
.select2-dropdown {
    z-index: 9999999999 !important;
}
.daily-table a {
    text-decoration: none !important;
}
.daily-table .select2-container {
    float: right;
}
.information-workers-content .select2-container {
    width: 20% !important;
}
.daily-table .input-wapp {
    float: inherit;
    margin-right: 0; 
}
.daily-table .select2-container--default .select2-selection--single, .information-workers-content .select2-container--default .select2-selection--single {
    border-style: none;
    background-color: #216b00 ;
    border-radius: 4px !important;
}
.daily-table .select2-container--default .select2-selection--single .select2-selection__rendered, .information-workers-content .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #fff;
}
.daily-table .select2-container--default .select2-selection--single .select2-selection__arrow b, .information-workers-content .select2-container--default .select2-selection--single .select2-selection__arrow b {
        border-color: #fff transparent transparent transparent;
}
.commen-daily > a {
    color: #595959;
}
.commen-daily > a > i {
    margin-right: 5px;
}
.post-commen textarea {
    width: 100%;
    border-radius: 0;
    border: 1px solid #666;
    margin: 10px 0px;
}
.post-commen textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #666;
}
.post-commen textarea::-moz-placeholder { /* Firefox 19+ */
  color: #666;
}
.post-commen textarea:-ms-input-placeholder { /* IE 10+ */
  color: #666;
}
.post-commen textarea:-moz-placeholder { /* Firefox 18- */
  color: #666;
}
.post-commen input {
    color: #fff;
    background-color: #0098f9;
    border-color: transparent;
    font-weight: bold;
    border-radius: 4px;
    margin-bottom: 10px;
}
.comemnt-daily-bottom-1 img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    float: left;
    margin-top: 5px;
}
.comemnt-daily-bottom-1 div {
    overflow: hidden;
    padding-left: 10px;
}
.comemnt-daily-bottom ul {
    list-style-type: none;
    padding: 0;
}
.comemnt-daily-bottom ul a {
    color: #595959;
    margin-left: 3px;
}
.comemnt-daily-bottom ul i {
    margin-right: 2px;
}
.table-hover>tbody>tr:hover {
    background-color: transparent;
}
.border-none {
    border-style: none !important;
}
.full-commen {
    display: none;
    transform: all 0,2s;
}
.open.full-commen{
    display: block;
}
.daily-edid  a {
    display: block;
    color: #595959;
    border: 1px solid #ccc;
    text-align: center;
    width: 25px;
}
.select2-container {
    width: 100% !important;
}
.select2-container--default .select2-selection--single {
    border-color: #e0e0e0;
    height: 33px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    height: 33px;
    padding: 2px 12px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: slategray;
}
.form-daily .form-group {
    margin-bottom: 10px;
}
.tab-daily {
    margin-right: 10px;
    margin-left: 10px;
}
.tab-daily [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}
.tab-daily, .tab-daily .row {
    margin-left: -5px;
    margin-right: -5px;
}
.tab-daily .form-control {
    border-radius: 0;
}
.checkbox-daily {
    list-style-type: none;
    padding: 0;
}
.checkbox-daily li {
    display: inline-block;
    width: 50%;
    float: left;
}
.checkbox-primary input[type=radio]:checked+label::after, .checkbox-primary input[type=checkbox]:checked+label::after {
    color: #000;
}
.checkbox-primary input[type=radio]:checked+label::before, .checkbox-primary input[type=checkbox]:checked+label::before {
    border: 1px solid #e0e0e0;
    background-color: transparent;
}
.checkbox-daily li a {
    background-color: #f28e1f;
    padding: 5px 15px;
    color: #FFF;
    margin-top: 10px;
    border-radius: 4px;
}
.information-workers {
    overflow: hidden;
    clear: both;
}
.information-workers img {
    float: left;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.information-workers-content {
    overflow: hidden;
    padding-left: 10px;
}
.information-workers-content p {
    color: #595959;
    line-height: 15px;
}
.information-workers-content p span {
    font-weight: bold;
}
.information-workers-content .form-group {
    overflow: hidden;
}
.information-workers-content .form-group .input-wapp {
    margin-right: 10px;
}
ul.daily-edid {
    padding: 0;
}
ul.daily-edid li {
    display: inline-block;
}
.avatar-table {
    overflow: hidden;
}
.avatar-table img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
}
.avatar-table-content {
    overflow: hidden;
    padding-left: 10px;
    cursor: pointer;
}
.avatar-table-content p {
    margin: 0;
}
.avatar-table-content p span {
    font-weight: bold;
}
.select2-search--dropdown {
    display: none;
}
.nav-tabs .form-group {
    margin: 10px;
    margin-bottom: 0;
}
.nav-tabs .input-search .form-control {
    border-radius: 0;
}
.cow-modal .modal-dialog {
    float: right;
    margin: 0;
}

/*-------------------------------------------------*/

.header-project{
    width: 100%;
    height: 50px;
    background: #f28e20;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 50px;
    padding-left: 20px;
}
.header-project a{
    color: #FFF;
    border: 1px solid #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    text-decoration: none !important;
}
.col-btn{
    text-align: right;
}
.col-btn span{
    display: block;
    border: 1px solid #ddd;
    width: 25px;
    height: 25px;
    line-height: 25px;
    color: #666;
}
.col-btn span:last-child{
    border-top: none;
}
.col-btn span a{
    color: #666;
}
.app-media .page-main-2 {
    background: #f6f6f6;
    margin: 40px 15px 15px;
}
.page-login-v3:before {
    background: #f28e20;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background-image: -webkit-linear-gradient(top, #f28e20 0%, #f28e20 100%);
    background-image: -o-linear-gradient(top, #f28e20 0%, #f28e20 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#f28e20));
    background-image: linear-gradient(to bottom, #f28e20 0%, #f28e20 100%);
}
.form-material .form-control, .form-material .form-control.focus, .form-material .form-control:focus {
    background-image: -webkit-gradient(linear,left top,left bottom,from(#f28e20),to(#f28e20)),-webkit-gradient(linear,left top,left bottom,from(#e0e0e0),to(#e0e0e0));
    background-image: -webkit-linear-gradient(#f28e20,#f28e20),-webkit-linear-gradient(#e0e0e0,#e0e0e0);
    background-image: -o-linear-gradient(#f28e20,#f28e20),-o-linear-gradient(#e0e0e0,#e0e0e0);
    background-image: linear-gradient(#f28e20,#f28e20),linear-gradient(#e0e0e0,#e0e0e0);
}
.form-material .form-control.focus~.floating-label, .form-material .form-control:focus~.floating-label {
    color: #f28e20;
}
.projects-wrap .btn-back, .projects-wrap .btn-continue{
    display: none;
}
.group-btn .btn-next, .group-btn .btn-back, .group-btn .btn-save, .tab1 .btn-add{
    display: none;
}
.vertical-step li a span{
    font-weight: 600;
}
.col-btn-2 span {
    float: left !important;
    text-align: center;
}
.col-btn-2 span:last-child {
    border-top: 1px solid #ddd;
    border-left: none;
}
.nav-tabs-inverse .nav-tabs-solid>li.active>a, .nav-tabs-inverse .nav-tabs-solid>li.active>a:focus, .nav-tabs-inverse .nav-tabs-solid>li.active>a:hover {
    color: #FFFFFF;
    background-color: #f28e20;
}
.nav-tabs-vertical .nav-tabs-solid+.tab-content {
    padding: 0;
}
.tab-pane-content{
    padding: 10px;
}
.tab-pane-content .form-group{
    margin-bottom: 0;
}
.tab-pane-content h4{
    text-transform: uppercase;
}
.tab-pane-content input.to-labelauty + label {
    min-width: 110px;
    border-radius: 0;
    margin-right: 5px;
    color: #FFFFFF;
    padding: 10px 8px;
}
.check-list-1 input.labelauty + label:nth-child(2),
.check-list-2 input.labelauty + label:nth-child(2),
.check-list-3 input.labelauty + label:nth-child(2),
.check-list-4 input.labelauty + label:nth-child(2){
    background: #00bcd4 !important;
}
.check-list-1 input.labelauty + label:nth-child(4),
.check-list-2 input.labelauty + label:nth-child(4),
.check-list-3 input.labelauty + label:nth-child(4),
.check-list-4 input.labelauty + label:nth-child(4){
    background: #4caf50 !important;
}
.check-list-1 input.labelauty + label:nth-child(6),
.check-list-2 input.labelauty + label:nth-child(6),
.check-list-3 input.labelauty + label:nth-child(6),
.check-list-4 input.labelauty + label:nth-child(6){
    background: #ff9800 !important;
}
.check-list-1 input.labelauty + label:nth-child(8),
.check-list-2 input.labelauty + label:nth-child(8),
.check-list-3 input.labelauty + label:nth-child(8),
.check-list-4 input.labelauty + label:nth-child(8){
    background: #f25a20 !important;
}
.check-list-1 input.labelauty + label:nth-child(10),
.check-list-2 input.labelauty + label:nth-child(10),
.check-list-3 input.labelauty + label:nth-child(10),
.check-list-4 input.labelauty + label:nth-child(10){
    background: #f44336 !important;
}
.vn-panel{
    background: #ededed;
    border-radius: 5px;
}
.vn-panel .panel{
    background: transparent;
}
.panel-group {
    margin-bottom: 10px;
}
.panel-group-continuous .panel+.panel {
    border-top: 1px solid #FFFFFF;
}
.work-group{
    display: table;
    width: 100%;
    height: 70px;
}
.work-group div{
    display: table-cell;
    vertical-align: middle;
}
.work-group div:first-child{
    width: 11%;
}
.work-group .avatar{
    width: 55px;
    height: 55px;
}
.work-group .avatar img{
    height: 55px;
}
.work-group .avatar button{
    padding: 0;
    margin: 0;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 2px solid #FFF;
    line-height: 12px;
    text-align: center;
    color: #FFF;
    background: #F00;
    position: absolute;
    right: 2px;
    outline: none;
}
.work-group div h4, .work-group div p{
    margin: 0;
}
.site-action-2 {
    position: relative;
    right: 0;
    bottom: 0;
    z-index: initial;
    -webkit-animation-duration: 3s;
    -o-animation-duration: 3s;
    animation-duration: 3s;
}
.btn-addnew {
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
    font-size: 36px;
    text-align: center;
    border-radius: 100%;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #FFFFFF;
}
.tab-pane-content .input-search-btn {
    z-index: 999;
}
.site-menubar-unfold .site-menu-title:first-child {
    margin-left: 0;
}
.table-list-works{
    background: #FFFFFF;
    padding: 10px;
}
.email-modal {
    top: auto;
    height: 494px;
}
.app-mailbox .select2-container {
  z-index: 1700;
}
.select2-container--default.select2-container--open .select2-selection{
    box-shadow: none !important;
}
.modal-sidebar .modal-header {
    background:#f28e20;
    
}
.modal-sidebar .modal-header h4, .modal-sidebar .modal-header button{
    color: #FFFFFF;
    opacity: 1;
}
.modal-sidebar .modal-footer {
    background: #e0e0e0;
}
.modal-sidebar button.modalMinimize {
    right: 40px;
    margin-top: 3px;
}

.hau-qua input.labelauty:checked + label, .hau-qua-2 input.labelauty:checked + label{
    background: #f28e20;
}
.tan-suat input.labelauty:checked + label, .tan-suat-2 input.labelauty:checked + label{
    background: #f44336;
}

.modal-sidebar .modal-content {
    height: 100%;
    overflow: auto;
}
.app-mailbox .panel-group .panel-title {
    position: relative;
    padding: 0px 10px;
    font-size: 14px;
}
.app-mailbox .panel-group .panel-title:after, .app-mailbox .panel-group .panel-title:before {
    top: 0px;
    right: 5px;
}
.app-mailbox .tab-pane-content input.to-labelauty + label {
    min-width: 82px;
    border-radius: 0;
    margin-right: 5px;
    color: #FFFFFF;
}
.header-step{
    padding: 0 10px;
}
.header-step .col-md-2 {
    width: 20%;
    height: 106px;
}
.header-step .steps.row {
    border: 1px solid #ddd;
}
.header-step .col-md-2 {
    width: 20%;
    border-right: 1px solid #ddd;
}
.header-step .col-md-2:last-child{
    border-right: none;
}
.tab-content tr td a{
    text-decoration: none;
    color: initial;
}
.step.current {
    color: #fff;
    background-color: #0195ff;
}
.nav-tabs-vertical .nav-tabs {
    max-width: 275px;
}
.left-report tr td{
    vertical-align: top;
    padding: 15px 0;
}
.left-report tr td p{
    margin: 0;
}
.left-report tr td .icon, .report-page .nav-tabs .icon {
    color: inherit !important;
    font-size: initial !important;
    float: initial !important;
    background: initial !important;
}
.left-report tr td .avatar img {
    height: 40px;
}
.report-page .projects-sort {
    padding: 0 10px 6px;
}
.report-page .current .step-number-2 {
    background: #FFFFFF;
    color: #00bcd4;
}
.report-page .current .step-number-2:before {
    border-bottom: 10px solid #FFFFFF;
}
.report-page .current .step-number-2:after {
    border-top: 10px solid #FFFFFF;
}
.content-report-info{
    padding: 10px;
}
.content-report-info .work-group div:first-child {
    width: 8%;
}
.content-report-info .work-group .info span{
    font-weight: 500;
}
.content-report-info .input-wapp input[type=checkbox] + label:before {
    content: "\f111";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    color: #FFF;
    margin-left: -20px;
    margin-right: 9px;
}
.content-report-info .input-wapp input[type=checkbox] + label {
    padding: 4px 23px 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.content-report-info .input-search .form-control {
    border-radius: 0;
}
.select2-container {
    z-index: 999999 !important;
}
.select2-container--default .select2-selection {
    border: none !important;
    border-bottom: 1px solid #e0e0e0 !important;
}
.select2-container .select2-dropdown {
    margin-top: 0px !important;
    border-color: transparent !important;
}
.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field {
    min-width: 18em !important;
}
.img-item img{
    max-width: 100%;
    height: 120px;
}
.title-report{
    font-size: 20px;
    font-weight: bold;
    color: #f28e20;
    text-align: center;
}
.group-btn span{
    display: block;
    text-align: center;
    color: inherit;
    float: left;
}
.group-btn span a{
    color: inherit;
}
.project-2 .panel{
    margin-bottom: 30px;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
}
.project-2 .blocks > li {
    margin-bottom: 10px !important;
}
.tab-content tr td a {
    color: #616161;
}
.nav-tabs .input-search .form-control {
    background: transparent;
}
.avatar-info-img{
    width: 240px;
    height: 240px;
    overflow: hidden;
    text-align: center;
    border-radius: 50%;
}
.user-group{
    padding: 10px 20px;
}
.user-name{
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
}
.user-info{
    text-align: center;

}
.user-info p {
    margin: 0 0 0px;
}
.input-label input[type=checkbox] + label {
    background: #00bcd4 ;
}
.input-label input[type=checkbox] + label:after {
    border-top: 11px solid #00bcd4;
    border-left: 11px solid #00bcd4;
}
.input-label {
    margin-right: 10px;
}
.active-label {
    padding: .7em 1em .5em;
}
.modal-header {
    padding: 8px 20px;
    background: #f28e20;
}
.modal-title {
    color: #FFF;
}
.close{
    color: #FFFFFF;
    opacity: 1;
}
.user-info-group .form-group {
    margin-bottom: 10px;
}
.role-avatar{
    width: 100%;
    height: 220px;
    background: #ededed;
}
.form-control{
    border-radius: 0;
}
.user-detail{
    padding: 30px;
}
.detail-avatar{
    background: #dadada;
    padding: 5px;
    overflow: hidden;
}
.detail-avatar img{
    width: 100%;
    height: auto;
}

.video-sidebar{
    position: fixed;
    right: 0;
    top: 26%;
    
}
.button-video{
    height: 50px;
    width: 50px;
    float: left;
    background: #FFFFFF;
    text-align: center;
    padding: 11px 0;
    border-right: 1px solid #ededed;
}
.video-content{
    float: right;
    width: 400px;
    height: 250px;
    background: #FFFFFF;
    padding: 0 20px;
}
.button-video button{
    background: transparent;
    border: 1px solid #757575;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    outline: none;
}
.header-sidebar{
    height: 50px;
    text-align: center;
    line-height: 50px;
    text-transform: uppercase;
    border-bottom: 1px solid #ededed;
}







/*////////////////////////////////////////VIDEO FIXED///////////////////////////////////////////*/

.check-training-table{
  display: block;
  overflow: hidden; 
  padding-bottom: 10px;
}


.check-training-table ul{
  list-style: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


.check-training-table ul li{
  color: #AAAAAA;
  display: inline-block;
  position: relative;
  float: left;
}

.check-training-table ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.check-training-table ul li label{
  display: block;
  position: relative;
  font-weight: 400;
  font-size:16px;
  padding: 20px 10px 0px 85px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #3c4144;
}

.check-training-table ul li .check{
  display: block;
  position: absolute;
  border: 3px solid #AAAAAA;
  border-radius: 100%;
  height: 20px;
  width: 20px;
    top: 23px;
    left: 50px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.check-training-table ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 2px;
    left: 2px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.check-training-table input[type=radio]:checked ~ .check {
  border: 3px solid #f28e20;
}

.check-training-table input[type=radio]:checked ~ .check::before{
  background: #f28e20;
}

.check-training-table input[type=radio]:checked ~ label{
  color: #3c4144;
}

.fixed-video {
    z-index: 99;
    top: 150px;
    right: 0;
    transform: translateX(100%);
    position: fixed;
}
.fixed-video__inner {
    display: inline-block;
    background: #fff;
    padding: 10px 20px 9px 20px;
    position: relative;
}
.fixed-video__button {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 10px;
    background: #fff;
    transform: translateX(-100%);
}
.fixed-video.active {
    transform: translateX(0);
    transition: all 0.3s ;
}

.check-training-table{
  display: block;
  overflow: hidden; 
  padding-bottom: 10px;
}


.check-training-table ul{
  list-style: none;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}


.check-training-table ul li{
  color: #AAAAAA;
  display: inline-block;
  position: relative;
  float: left;
}

.check-training-table ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

.check-training-table ul li label{
  display: block;
  position: relative;
  font-weight: 400;
  font-size:16px;
  padding: 20px 10px 0px 85px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  color: #AAAAAA;
}

.check-training-table ul li .check{
  display: block;
  position: absolute;
  border: 3px solid #AAAAAA;
  border-radius: 100%;
  height: 20px;
  width: 20px;
    top: 23px;
    left: 50px;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.check-training-table ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 50%;
    height: 9.5px;
    width: 9.5px;
    top: 2.5px;
    left: 2.7px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

.check-training-table input[type=radio]:checked ~ .check {
  border: 3px solid #AAAAAA;
}

.check-training-table input[type=radio]:checked ~ .check::before{
  background: #AAAAAA;
}

.check-training-table input[type=radio]:checked ~ label{
  color: #AAAAAA;
}

.fixed-video__title {
    text-transform: uppercase;
    text-align: center;
    font-size: 17px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
    margin-bottom: 13px;
}

.footer-menu-fixd{
    display: none;
}



/*////////////////////////////////////////////////*/


.site-menu-item {
    border-bottom: 1px solid #000;
}

.site-menu-sub > .site-menu-item:last-child{
    border-bottom: none;
}
.app-projects .overlay-panel {
    background: rgba(0, 0, 0, .7) !important;
    overflow: hidden;
}
.site-menubar-info .dropdown-menu {
    z-index: 999999999;
    top: 48px;
    left: 58%;
}
.scrollable.is-enabled {
    z-index: -1;
}
.btn-inverse, .btn-inverse:hover {
    color: #FFF;
    background: transparent;
    border-color: #eee;
}
.site-menubar-unfold .site-menu-title {
    padding-left: 8px;
}
.site-menubar-info{
    padding: 5px 0;
}
.page-header {
    padding: 15px 30px 5px;
}
.blocks>li, [class*=blocks-]>li {
    padding-right: 15px;
    padding-left: 15px;
}
.page-header-actions {
    top: 87%;
    right: 30px;
}
.app-media .page-header {
    padding: 20px 30px 18px 45px;
}
.app-media .page-header-actions {
    top: 50%;
    right: 15px;
}
.app-projects .overlay {
    margin-bottom: 8px;
}
.nav-tabs-vertical .nav-tabs>li:first-child{
    border-top: 1px solid #FFF;
}
.panel-group .panel-heading+.panel-collapse .panel-body {
    word-break: break-all;
}
.navbar-search-overlap {
    z-index: 9999;
}
.page{
    background: url('/assets/dist/img/main-bg.jpg') repeat;
}
.nav-tabs-vertical{
    background: #f6f6f6;
}
.project-2 .blocks, [class*=blocks-] {
    margin-right: -5px;
    margin-left: -5px;
}
/*.project-2 .blocks>li, [class*=blocks-]>li {
    padding-right: 5px;
    padding-left: 5px;
}*/
.project-2 .nav-tabs-vertical .tab-content {
    padding: 15px 30px;
}
.project-2_1 .nav-tabs-vertical .tab-content {
    padding: 0;
}
.breadcrumb{
    margin: 0;
    padding: 0;
}
.project-3 .page-content{
    padding:20px;
}
.page-main-2{
    background: #FFFFFF;
}
.hight input[type=checkbox] + label:after {
    border-top: 11px solid #ef5350;
    border-left: 11px solid #ef5350;
}





/*/////////////////////////////*/
.desktop{
    width: 573px;
    margin: 30px auto;
    position: relative;
}
.desktop img{
    position: relative;
}
.video-embed{
    position: absolute;
    width: 100%;
    height: 344px;
    top: 0;
    padding: 30px;
}
.table-cow p{
    margin-bottom: 0;
}
.table-cow td{
    padding: 5px 0;
    font-weight: 500;
    padding-right: 15px;
}
.table-cow td span:first-child{
    width: 88px;
    text-align: center;
}
.training-form .radio-custom label{
    font-weight: 500;
}
.training-form .radio-custom input[type=radio]{
    outline: none;
}
.training-form .radio-custom input[type=radio]:checked+label::before {
    border-width: 3px;
    outline: none;
}
.training-form .radio-custom label::after {
    top: 6px;
    margin-left: -21px;
    border: 5px solid #ff9800 !important;
}
.training-form .checkbox-custom, .radio-custom {
    margin-top: 0;
    margin-bottom: 0;
}
.training-form a {
    background-color: #26c6da;
    border-color: #26c6da;
    margin: 10px;
    border-radius: 4px;
    text-transform: none;
    color: #FFF !important;
}
.table a {
    text-decoration: none;
}
.training-form td{
    padding: 3px 0;
}
.time-training{
    position: absolute;
    background: #FFFFFF;
    width: 250px;
    right: 0;
    z-index: 99999;
    box-shadow: 0px 0px 10px #888888;
    padding: 10px;
    top: 75px;
}
.time-training div{
    display: table-cell;
    vertical-align: middle;
    height: 60px;
    font-weight: bold;
    text-align: center;
    width: 70px;
}
.time-training div span{
    display: inline-block;
    width: 50px;
    height: 40px;
    border: 1px solid #888;
    border-radius: 4px;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
}
.time-training div label{
    display: block;
}
.time-training div.doted{
    font-size: 20px;
    width: 10px;
    text-align: center;
    padding-top: 23px;
}







/*///////////////////////////////////*/
.db-list-project{
    height: 300px;
    background: #f6f6f6;
    overflow:auto;
}

.group .blocks > li {
    margin-bottom: 10px !important;
}
.group .nav-tabs-horizontal{
    padding:15px;
    background: #FFFFFF;
}
.tabs-header{
    border-bottom: none;
}
.tabs-header li{
    width: 50%;
}
.tabs-header li a, .tabs-header li a:hover{
    margin-right: 0;
    border: 1px solid #e0e0e0;
    background: transparent;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
}
.tabs-header li.active a{
    border: 1px solid #e0e0e0;
}
.tabs-header>li.active>a, .tabs-header>li.active>a:focus, .tabs-header>li.active>a:hover {
    border: 1px solid #e0e0e0;
}
.tabs-header li:first-child a{
    border-top-left-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-right: none;
}
.tabs-header li:last-child a{ 
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-left: none;
}
.scrollbar
{
    float: left;
    max-height: 264px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}
#style-4::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
    width: 4px;
    background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
    background-color: #000000;
    border: 2px solid #555555;
}
.group .table>tbody>tr:first-child>td{
    border-top: none;
}
.group .table>tbody>tr>td p span{
    font-weight: 300;
    opacity: .6;
    font-size: 12px;
    margin-left: 20px;
}

.info-user .avatar-info-img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}
.info-user .avatar-info-img img{
    max-width: 100%;
    height: 100%;
}
.list-user{
    width: 52%;
    float: right;
    min-height: 315px;
}
.panel-title {
    padding: 15px !important;
    background: #f28e20;
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
}

.panel-title a{
    border: 1px solid #FFF;
    padding: 5px;
    border-radius: 4px;
    text-decoration: none !important;
}

.step-ra .panel-title{
    background: transparent;
    text-transform: none;
    color: #000;
}
#trapezoid {
    border-bottom : 47px solid #0096ff;
    border-left   : 28px solid transparent;
    border-right  : 28px solid transparent;
    height        : 0;
    width         : 150px;
    box-sizing    : content-box;
}

#trapezoid-1 {
    border-bottom : 40px solid #ffc000;
    border-left   : 23px solid transparent;
    border-right  : 23px solid transparent;
    height        : 0;
    width         : 103px;
    box-sizing    : content-box;
    margin-bottom : 1px;
    margin-left   : 29px;
}

#trapezoid-2 {
    border-bottom : 30px solid #f28d20;
    border-left   : 17px solid transparent;
    border-right  : 17px solid transparent;
    height        : 0;
    width         : 68px;
    box-sizing    : content-box;
    margin-bottom : 1px;
    margin-left   : 52px;
}

#trapezoid-3 {
    width         : 0;
    height        : 0;
    border-left   : 33.5px solid transparent;
    border-right  : 33.3px solid transparent;
    border-bottom : 53px solid #d20001;
    margin-left   : 69.5px;
    margin-bottom : 1px;
}

.chart-info li {
    list-style    : none;
    margin-bottom : 13px;
}

.chart-info li span {
    display      : block;
    width        : 20px;
    height       : 20px;
    float        : left;
    margin-right : 10px;
}

.chart-info li:nth-child(4) span {
    background : #0096ff;
}

.chart-info li:nth-child(3) span {
    background : #ffc000;
}

.chart-info li:nth-child(2) span {
    background : #f28d20;
}

.chart-info li:nth-child(1) span {
    background : #d20001;
}
.project-image{
    text-align: center;
}
.project-image img{
    max-width: 100%;
    margin: 0 auto;
}
.project-detail{
    padding-top: 10px;
}

.menu-none .page, .menu-none .site-footer{
    margin-left: 0 !important;
}
.admin{
    padding: 10px;
    font-weight: 500;
}
.nav-tabs-vertical .nav-tabs>li>a {
    padding: 10px 25px;
    border-radius: 0;
}

.nav-tabs-vertical .project-folder>li>a {
    padding: 10px 10px;
    line-height: 34px;
    border: none;
    margin-right: 1px;
}

.nav-tabs-vertical .project-folder .icon {
    color: #f28e20;
    font-size: 30px;
    float: left;
}
.project-folder>li.active>a .icon {
    color: #FFFFFF;
}
.nav-tabs-vertical .nav-tabs>li.active>a{
    background: #f28e20;
}

.progress-bar-yollow{
    background: #FFD740;
}
.right-icon {
    position: absolute;
    float: right;
    margin: 8px;
    display: block;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(255, 255, 255, .5);
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, .9);
    right: 0;
    top: 0;
}
.right-icon:active, .right-icon:focus{
    color: rgba(255, 255, 255, .9);
}

.project-sort {
    float: right;
    width: 130px;
    margin: 8px;
    background: #f28e20 !important;
    color: rgba(255, 255, 255, .9);
    border: 1px solid rgba(255, 255, 255, .5);
    position: absolute;
    right: 0;
    top: 0;
}
.hscd, .hsbd{
    background: #f28e20;
    color: #fff;
    font-size: 18px;
    padding: 7px;
    width: 55%;
    text-align: center;
    margin:0 auto 20px;
    border-radius: 10px;
}
.hscd{
    margin-bottom: 0;
}
.progress{
    margin-bottom: 18px;
}
.project-sort, .project-sort:active, .project-sort:focus{
    border-color: #FFFFFF !important;
    box-shadow: none !important;
}
.menu-none #toggleMenubar{
    display: none;
}
.list-projects li{
    padding:0 0 12px;
}
.list-projects li:last-child{
    padding:0 0 0;
}
.list-projects li a{
    color: #757575;
}
.list-projects .time{
    font-size: 12px;
    font-style: italic;
}
.app-projects .blocks > li {
    margin-bottom:0 !important;
}
.app-projects .mr-bt-30 {
    margin-bottom: 30px !important;
}
.mh-274{
    min-height: 274px;
}
.empty-group{
    background: rgba(243, 244, 245, 0.5);
    border:1px dashed #ccc !important;
     text-align: center;
    line-height: 250px;
    color: #ccc;
}
.site-menu > .site-menu-item.open > a{
    color: #FFFFFF;
}
.xblocks{
    margin-right: -15px;
    margin-left: -15px;
}
.app-projects .projects-wrap {
    padding-top: 10px !important;
}
.page{
    min-height: calc(100vh - 110px);
}
.footer {
  height: 40px;
}
.avatar img {
    height: 29px;
}
.tab-content>.active {
    min-height: calc(100vh - 0px);
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    border: none !important;
}
.modal-open .modal {
    /*z-index: 99999 !important;*/
}
.site-menubar-unfold .site-menubar {
    z-index: 0 !important;
}
.vh50{
    min-height: calc(30vh - 0px) !important;
}
.nav-tabs-vertical .projects-sort {
    padding: 0 10px 0;
    height: 45px;
}

#button {
    height: 31px !important;
    line-height: 31px !important;
    width: 79.5% !important;
}
.menpow .input-wapp {
    width: 50%;
}
.step-ra .tab-pane-content{
    height: 650px;
    overflow-y: auto;
    min-height: 650px;
}

.open > .dropdown-menu {
    bottom: auto;
}
.modal-body .tab-content > .active {
    min-height: auto !important;
}
.line-header-left span img {
    height: auto;
    max-width: 100%;
}
.employee-card .line-header-left span {
    width: auto;
    height: 30px;
    padding: 3px;
    border: none;
    width: 100px;
    margin: 7px 3px 3px -118px !important;
    transform: skew(-50deg);
    float: left;
}

.site-footer-legal {
    float: right !important;
}
.site-menubar-hide .site-footer {
    margin-left: 0 !important;
}
.bg-change{
    background: transparent;
    color: #757575;
}
.input-wapp.pointer{
    width: 90%;
}
@media (max-width: 767px){
    .site-menubar-open .page, .site-menubar-open .site-footer {
        -webkit-transform: none;
        -ms-transform: none;
        -ms-transform: none;
        transform: none;
    }
    .list-projects ul{
        margin: 0;
        padding:  0 7px;
    }
    .btn-up {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        top: -24px;
        width: 56px;
        border: 1px solid #ddd;
        border-bottom: none;
        background: #FFF;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;
        outline: none;
    }
    .project-2 .blocks, [class*=blocks-] {
        margin-right: -15px;
        margin-left: -15px;
    }
    .page-content {
        padding: 0 15px !important;
    }
    .blocks-xs-100 li{
        width: 100% !important;
    }
    .project-sort {
        width: 100px;
    }
    .hscd, .hsbd {
        width: 80%;
    }
    .page-title {
        margin-top: 0 !important;
    }
    .page-header {
        height: auto !important;
    }
    .page-header-actions {
        top: 25px !important;
    }
    
    .footer-menu-fixd {
        bottom: -70px !important;
    }
    .open {
        bottom: -1px !important;
    }
    .fixed-video{
        display: none !important;
    }
    .navbar-brand-logo {
        height: 25px;
    }
}
